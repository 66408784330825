import React, {Component} from 'react';
import ImageCarousel from "../ImageCarousel";

export default class portfolio extends Component{
    render(){
        return(
            <ImageCarousel>
            </ImageCarousel>
        );
    }
}